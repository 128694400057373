<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Warehouse products">
                <delete-items-button v-if="$hasPermission($permissions.RemoveProducts)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>

                <b-button v-if="$hasPermission($permissions.AddProducts)" variant="primary" @click="$refs.addModal.open()">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
                
                <basic-table ref="basicTable" :columns="columns" :data="warehouseProducts" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 3 && $hasPermission($permissions.RemoveProducts)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditProducts)" variant="warning" @click="$refs.editModal.open(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemoveProducts)" variant="danger" @click="removeWarehouseProduct(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <div v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <table>
                            <tr v-for="(product, index) in props.row.products" :key="index" style="vertical-align: middle">
                                <td class="pb-50 pt-50">{{product.name}}</td>
                                <td class="pb-50 pt-50">{{product.quantity}}x</td>
                            </tr>
                        </table>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
        
        <AddWarehouseProductModal :products="products" ref="addModal" v-on:itemAdded="loadData"/>

        <EditWarehouseProductModal :products="products" ref="editModal" v-on:itemSaved="loadData"/>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import AddWarehouseProductModal from '@/views/WarehouseProduct/AddWarehouseProductModal'
    import EditWarehouseProductModal from '@/views/WarehouseProduct/EditWarehouseProductModal'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            DeleteItemsButton,
            EditWarehouseProductModal,
            AddWarehouseProductModal,
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                warehouseProducts: [],

                products: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 3,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Quantity multiplier',
                        displayType: 0,
                        field: 'quantity_multiplier',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Products',
                        displayType: 2,
                        field: 'products',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {
            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/warehouse_product')
                loadPromise.then(function(response) {
                    thisIns.warehouseProducts = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                const productsLoadPromise = this.$http.get('/api/management/v1/product')
                productsLoadPromise.then(function(response) {
                    thisIns.products = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise, productsLoadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            async removeWarehouseProduct(id) {

                try {

                    const confirmDelete = await this.$confirmDialog('Do you want to remove this warehouse product?')
                    
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/management/v1/warehouse_product/${  id}`)
                    this.$printSuccess('Warehouse product removed')

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)                    
                } finally {
                    this.loadData()
                }
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },
        mounted() {
            this.selection.collection_name = 'warehouse_products'
            this.loadData()
        }
    }
</script>